<template>
  <div>
    <PageHeader :items="items">
      <template #filters>
        <div class="search-filters">
          <SearchFilter :isFilterSearch="false">
            <li>
              <div class="form-group">
                <label>{{ $t("Invoice Type") }} </label>
                <multiselect
                  v-model="form.invoiceType"
                  :key="form.invoiceType"
                  :options="['invoice-correction', 'invoice', 'invoice-storno']"
                  :multiple="false"
                >
                </multiselect>
              </div>
              <div class="form-group">
                <label>{{ $t("Status") }} </label>
                <multiselect
                  v-model="form.status"
                  :key="form.status"
                  :options="[
                    'draft',
                    'approved',
                    'sent',
                    'warning level 1',
                    'warning level 2',
                    'warning level 3',
                    'paid',
                  ]"
                  :multiple="false"
                >
                </multiselect>
              </div>
            </li>
          </SearchFilter>
          <div class="page-header-search">
            <input
              autocomplete="off"
              type="text"
              name="search"
              :placeholder="$t('Search…')"
              v-model="form.search"
            />
            <button class="" type="button" @click="reset">
              {{ $t("Reset") }}
            </button>
          </div>
        </div>
      </template>
      <template #buttons>
        <!-- <button @click="downloadInvoicePlan()" class="btn btn-primary">
          <span>{{ $t("Export Invoice Plan") }}</span>
        </button> -->
        <button
          v-if="$can('invoice.export-csv')"
          @click="downloadCSV()"
          class="btn btn-primary"
        >
          <span>{{ $t("Export CSV") }}</span>
        </button>
        <!-- <button @click="downloadLatestCSV()" class="btn btn-primary">
          <span>{{ $t("Export Latest CSV") }}</span>
        </button> -->
      </template>
    </PageHeader>
    <div class="custom-search d-md-flex justify-content-between mb-1">
      <div class="d-flex align-items-center">
        <div class="checkbox-group mr-2">
          <input
            type="checkbox"
            v-model="selectAll"
            @change="toggleSelectAll"
            class="checkbox-input"
            id="check"
          />
          <label for="check" class="checkbox-label">{{
            $t("Select All")
          }}</label>
        </div>
        <button @click="generateSelected()" class="btn btn-primary">
          <span>{{ $t("Generate Selected") }}</span>
        </button>
      </div>
      <div class="d-flex align-items-center mt-md-0 mt-1">
        <b-form-group class="m-0">
          <div class="d-flex align-items-center">
            <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
            <b-form-select
              v-model="pageLength"
              :options="['25', '50', '100']"
              class="ml-1"
              @input="(value) => onPerPageChange({ pageLength: value })"
            />
          </div>
        </b-form-group>
      </div>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="dextop-table-view">
      <div class="table-responsive api-keys-table">
        <!-- table -->
        <vue-good-table
          styleClass="vgt-table striped"
          :columns="columns"
          :fixed-header="false"
          :rows="rows"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->

            <span
              class="d-flex align-items-center justify-content-center gap-2"
              v-if="props.column.field === 'action'"
            >
              <div
                @click="generate(props.row)"
                class="cursor-pointer"
                :title="$t('Generate Document')"
              >
                <feather-icon size="16" icon="FileTextIcon" />
              </div>
              <div
                class="cursor-pointer"
                :title="$t('Show')"
                @click="$router.push(`/invoices/${props.row.id}/show`)"
              >
                <feather-icon size="16" icon="EyeIcon" />
              </div>
            </span>

            <span v-else-if="props.column.field === 'totalAmount_numeric'">
              {{
                $formatter(
                  props.row.totalAmount,
                  $i18n.locale,
                  "EUR",
                  false,
                  2,
                  2
                )
              }}
            </span>

            <span v-else-if="props.column.field === 'dueDate'">
              <p class="">
                {{ $dateFormatter(props.row.dueDate, $i18n.locale) }}
              </p>
            </span>

            <span v-else-if="props.column.field === 'netto_numeric'">
              {{
                $formatter(props.row.netto, $i18n.locale, "EUR", false, 2, 2)
              }}
            </span>
            <span v-else-if="props.column.field === 'Company.companyName'">
              {{ props.row.companyName }}
            </span>

            <span v-else-if="props.column.field === 'taxAmount_numeric'">
              {{
                $formatter(
                  props.row.taxAmount,
                  $i18n.locale,
                  "EUR",
                  false,
                  2,
                  2
                )
              }}
            </span>
            <span v-else-if="props.column.field === 'checkbox'">
              <div class="checkbox-group">
                <input
                  :id="'row-' + props.row.id"
                  v-model="selectedRows"
                  :value="props.row.id"
                  type="checkbox"
                  class="checkbox-input"
                />
                <label
                  :for="'row-' + props.row.id"
                  class="checkbox-label m-0"
                ></label>
              </div>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-end flex-wrap">
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="totalRecords"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => onPageChange({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="reponsive-view-cards">
      <div class="row">
        <div class="col-md-4" v-for="row in rows" :key="row.id">
          <div class="card mb-1">
            <div class="card-header">
              <h4>{{ row.companyName }}</h4>
              <div class="d-flex align-items-center gap-2">
                <div
                  @click="generate(row)"
                  class="cursor-pointer"
                  :title="$t('Generate Document')"
                >
                  <feather-icon size="18" icon="FileTextIcon" />
                </div>
                <div
                  class="cursor-pointer"
                  :title="$t('Show')"
                  @click="$router.push(`/invoices/${row.id}/show`)"
                >
                  <feather-icon size="18" icon="EyeIcon" />
                </div>
              </div>
            </div>
            <div class="card-body">
              <p>
                <strong>{{ $t("Invoice Number") }}:</strong>
                {{ row.invoiceNumber }}
              </p>
              <p>
                <strong>{{ $t("Invoice Type") }}:</strong>
                {{ row.invoiceType }}
              </p>
              <p>
                <strong>{{ $t("Due Date") }}:</strong>
                {{ $dateFormatter(row.dueDate, $i18n.locale) }}
              </p>
              <p>
                <strong>{{ $t("Status") }}:</strong> {{ row.status }}
              </p>
              <p>
                <strong>{{ $t("Netto") }}:</strong>
                {{ $formatter(row.netto, $i18n.locale, "EUR", false, 2, 2) }}
              </p>
              <p>
                <strong>{{ $t("Tax Amount") }}:</strong>
                {{
                  $formatter(row.taxAmount, $i18n.locale, "EUR", false, 2, 2)
                }}
              </p>
              <p>
                <strong>{{ $t("Total Amount") }}:</strong>
                {{
                  $formatter(row.totalAmount, $i18n.locale, "EUR", false, 2, 2)
                }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-2">
        <b-pagination
          :value="1"
          :total-rows="totalRecords"
          :per-page="pageLength"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @input="(value) => onPageChange({ currentPage: value })"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
    <!--====================================-->
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapGetters } from "vuex";
import NotificationService from "../../../services/notification.service";
import Multiselect from "vue-multiselect";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import SearchFilter from "@/components/SearchFilter.vue";
import { props } from "vue-prism-component";
import { debounce } from "@/utils/debounce.js";
export default {
  components: {
    PageHeader,
    Multiselect,
    SearchFilter,
    MultiSelectInput,
  },
  computed: {
    optionalItems() {
      return {};
    },
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Invoices"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          label: this.$t(""),
          field: "checkbox",
          sortable: false,
        },
        {
          label: this.$t("Invoice Number"),
          field: "invoiceNumber",
        },
        {
          label: this.$t("Receiver"),
          field: "Company.companyName",
        },
        {
          label: this.$t("Invoice Type"),
          field: "invoiceType",
        },
        {
          label: this.$t("Due Date"),
          field: "dueDate",
        },
        {
          label: this.$t("Status"),
          field: "status",
        },
        {
          label: this.$t("Netto"),
          field: "netto_numeric",
        },
        {
          label: this.$t("Tax Amount"),
          field: "taxAmount_numeric",
        },
        {
          label: this.$t("Total Amount"),
          field: "totalAmount_numeric",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Action"),
          field: "action",
          sortable: false,
        },
      ];
    },
  },
  watch: {
    selectedRows(newVal) {
      this.selectAll = newVal.length === this.rows.length;
    },
    form: {
      handler() {
        this.debouncedFetch();
      },
      deep: true,
    },
  },
  data() {
    return {
      options: {
        //Monaco Editor Options
      },
      selectedMailInvoice: {},
      showModal: false,
      totalRecords: 0,
      pageLength: 25,
      selectAll: false,
      selectedRows: [],
      page: 1,
      rows: [],
      sortBy: "createdAt",
      sortOrder: "desc",
      form: {
        invoiceType: "",
        status: "",
        type: "",
      },
      modelData: {},
      selectedInvoice: {},
      reminderLevels: [],
      invoiceTemplate: {},
      invoiceEmail: "",
      showPromptModal: false,
    };
  },

  async created() {
    this.debouncedFetch = debounce(async (newValue, oldValue) => {
      try {
        await this.loadItems();
      } catch (e) {
        console.error(e);
      }
    }, 300);
    const response = await this.$store.dispatch(
      "invoices/documentAssignmentList"
    );
    this.modelData = response?.data?.data ?? {};
    let reminders = await this.$store.dispatch("invoiceReminderLevel/list");
    this.reminderLevels = reminders?.data?.data;
  },
  async mounted() {
    await this.loadItems();
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    async downloadCSV() {
      try {
        await this.$store.dispatch("invoices/download");
      } catch (e) {}
    },
    async downloadLatestCSV() {
      try {
        await this.$store.dispatch("invoices/downloadLatestCSV");
      } catch (e) {}
    },
    async downloadInvoicePlan() {
      try {
        await this.$store.dispatch("invoices/downloadInvoicePlan");
      } catch (e) {}
    },

    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },
    closeModal() {
      this.showModal = false;
    },
    toggleSelectAll() {
      if (this.selectAll) {
        // Map over rows to extract all IDs
        this.selectedRows = this.rows.map((row) => row.id);
      } else {
        this.selectedRows = [];
      }
    },
    async generate(invoice) {
      let templateId;

      if (invoice.status.startsWith("warning level")) {
        const matchedLevel = this.reminderLevels.find(
          (level) => level.levelName === invoice.status
        );

        templateId = matchedLevel ? matchedLevel.documentTemplateId : "";
      } else {
        // Handle other cases based on the form status
        switch (invoice.status) {
          case "invoice":
            templateId = this.modelData?.invoiceTemplateId ?? "";
            break;
          case "invoice-correction":
            templateId = this.modelData?.invoiceCorrectionTemplateId ?? "";
            break;
          case "invoice-storno":
            templateId = this.modelData?.invoiceStornoTemplateId ?? "";
            break;
          default:
            templateId = this.modelData?.invoiceTemplateId ?? ""; // Fallback to invoiceTemplateId
        }
      }
      if (templateId != "") {
        // Safeguard to ensure convertDate returns a valid Date object
        const parseDate = (date) => {
          const parsedDate = this.convertDate(date);
          return parsedDate instanceof Date && !isNaN(parsedDate)
            ? parsedDate
            : new Date(date);
        };

        const invoiceDate = invoice?.invoiceDate
          ? parseDate(invoice.invoiceDate)
          : this.formatDateLite(new Date());

        const startDate = invoice?.startDate
          ? parseDate(invoice.startDate)
          : null;

        const endDate = invoice?.endDate ? parseDate(invoice.endDate) : null;

        const dueDate = invoice?.dueDate ? parseDate(invoice.dueDate) : null;

        const draftStatusChangeDate = invoice?.invoiceDate
          ? parseDate(invoice.invoiceDate)
          : null;

        // Create the payload
        const payload = {
          ...invoice,
          customers: invoice.company,
          invoiceStatus: invoice.status,
          totalAmount: invoice.totalAmount,
          totalTaxAmount: invoice.taxAmount,
          totalAmountWithoutTax: invoice.netto,
          invoiceDate: invoiceDate.toLocaleDateString(),
          startDate: startDate ? startDate.toLocaleDateString() : null,
          endDate: endDate ? endDate.toLocaleDateString() : null,
          dueDate: dueDate ? dueDate.toLocaleDateString() : null,
          draftStatusChangeDate: draftStatusChangeDate
            ? draftStatusChangeDate.toLocaleDateString()
            : "",
          id: templateId,
          output: "pdf",
          updatedTime: Date.now(),
        };

        // Generate the filename
        const filename =
          "invoice-" +
          (invoice.invoiceNumber == null ? "draft" : invoice.invoiceNumber) +
          `.${"pdf"}`;

        // Dispatch the action
        this.$store.commit("showLoader", true);
        const response = await this.$store
          .dispatch("documentService/processTemplate", {
            data: payload,
            filename: filename,
            documentType: "pdf",
          })
          .finally(() => {
            this.$store.commit("showLoader", false);
          });

        // If response is Blob, convert to Base64
        if (response instanceof Blob) {
          await this.convertBlobToBase64(response);
        }
      } else {
        NotificationService.showError("Please assign document template first");
      }
    },
    async generateSelected() {
      // Filter rows where invoice.id exists in selectedRows array
      const selectedInvoices = this.rows.filter((invoice) =>
        this.selectedRows.includes(invoice.id)
      );
      for (let i = 0; i < selectedInvoices.length; i++) {
        try {
          await this.generate(selectedInvoices[i]); // Try generating
        } catch (error) {
          console.error(
            `Error processing invoice ${selectedInvoices[i].id}:`,
            error
          );
        }
      }
    },

    convertDate(dateString) {
      // Convert the date string into a Date object
      const date = new Date(dateString);

      // Convert the date to the desired format (Pakistan Standard Time)
      // Forcing timezone shift by setting the time to GMT+0500
      date.setTime(date.getTime() + 5 * 60 * 60 * 1000); // Add 5 hours

      // Return the formatted string
      return date.toString();
    },
    formatDateLite(date, showTime = false) {
      try {
        const m = date.getMonth() + 1;
        const d = date.getDate();
        return `${date.getFullYear()}-${m < 10 ? "0" : ""}${m}-${
          d < 10 ? "0" : ""
        }${d}${showTime ? " " + date.toLocaleTimeString() : ""}`;
      } catch (e) {
        return date;
      }
    },
    onSortChange(params) {
      this.sortOrder = params[0].type;
      if (params[0].type == "none") this.sortOrder = "asc";

      this.sortBy = params[0].field;
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        let response = await this.$store.dispatch("invoices/list", {
          page: this.page,
          ...this.form,
          sortBy: this.sortBy,
          sortOrder: this.sortOrder,
          perPage: this.pageLength,
        });
        this.rows = response?.data?.data;
        this.totalRecords = response?.data?.total;
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    reset() {
      this.form = {
        invoiceType: "",
        status: "",
        search: "",
      };
    },
  },
};
</script>

<style>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
